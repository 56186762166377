.app-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
  align-items: center;
  border-bottom: 3px black solid;
  height: 30px;
}

.link-header {
  z-index: 10;
}

.logo-button {
  display: flex;
  width: 200px;
  height: 50px;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 2px solid #222222;
  border-radius: 500px;
  transition-duration: 0.4s;
  margin-top: 8px;
  margin-bottom: -40px;
  margin-left: 15px;
  margin-right: 20px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.logo-button:hover {
  background-color: #222222;
  color: white;
}

.logo-image {
  width: 131px;
  margin-left: 10px;
  margin-right: 10px;
}

.header-menu-button {
  margin-top: 8px;
  margin-bottom: -30px;
  font-family: 'Mabry Pro Bold';
  font-size: 14px;
  /* line-height: 20px; */
  letter-spacing: 0.5px;
  margin-right: 12px;
  margin-left: auto;
  z-index: 10;
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

.display-none {
  display: none;
}

@media (min-width: 620px) {
  .logo-button {
    width: 240px;
    height: 60px;
    margin-bottom: -50px;
  }

  .logo-image {
    width: 158px;
  }
}
