.lang-section-container {
  border-top: 3px solid;
}

.lang-section-subcontainer {
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 20px;
}

.lang-section-heading {
  font-family: 'Mabry Pro Black';
  font-size: 25px;
  line-height: 32px;
}

.lang-section-subheading {
  margin-top: 8px;
  align-self: left;
  font-family: 'Mabry Pro Medium';
  font-size: 18px;
  line-height: 21px;
}

@media (min-width: 660px) {
  .lang-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid black;
    width: 550px;
  }

  .lang-section-subcontainer {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .lang-section-heading {
    font-family: 'Mabry Pro Black';
    font-size: 33px;
    line-height: 43px;
  }

  .lang-section-subheading {
    margin-top: 8px;
    align-self: left;
    font-family: 'Mabry Pro Medium';
    font-size: 25px;
    line-height: 28px;
  }

  .lang-selector button {
    font-family: 'Mabry Pro Bold';
    font-size: 17px;
    line-height: 23px;
    letter-spacing: 0.5px;
  }
}
