.app-footer {
  display: none;
}

@media (min-width: 620px) {
  .app-footer {
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
    position: relative;
    padding: 12px;
    bottom: 0;
    background-color: var(--gray1-color);
    color: white;
  }

  .app-footer p {
    font-family: 'Mabry Pro Regular';
    font-size: 21px;
    line-height: 21px;
    text-align: center;
    align-self: center;
  }
}
