.map-page-heading {
  font-family: 'Mabry Pro Bold';
  font-size: 32px;
  margin-bottom: 12px;
}

.map-container {
  height: calc(100% - 30px);
}

canvas {
  width: 100%;
  height: 100%;
}

/* Hide the old tip */
.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-content {
  border: none;
  background: none;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #ffffff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: relative;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.mapbox-logo {
  display: none;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}
.mapbox-improve-map {
  display: none;
}
.mapboxgl-ctrl-compass {
  display: none;
}

@media (min-width: 660px) {
  .map-container {
    height: calc(100% - 72px);
  }
}
