.poi-tile-container {
  display: flex;
  /* height: 30vh; */
  max-width: 60vw;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 12px;
  padding: 12px;
  margin-top: 20px;
  border: 1px solid black;
  background-color: var(--orange-color);
}

.poi-tile-title {
  font-family: 'Mabry Pro Black';
  font-size: 21px;
  line-height: 25px;
}

.poi-tile-tagline {
  font-family: 'Mabry Pro Regular';
  font-size: 14px;
  line-height: 18px;
}
.poi-tile-arrow {
  align-self: flex-end;
}

@media (min-width: 620px) {
  .poi-tile-container {
    max-width: 30vw;
  }
}
