.poi-detail-photo-gallery {
}

.poi-detail-photo-gallery ul {
  display: flex;
  flex-direction: column;
  column-gap: 12px;
}

.poi-detail-photo-gallery h2 {
  font-family: 'Mabry Pro Black';
  font-size: 29px;
  line-height: 32px;
  margin-bottom: 12px;
}

.photo-gallery-title {
  /* margin-top: 2px; */
  margin-bottom: 32px;
  display: flex;
}

.photo-gallery-title h3 {
  font-family: 'Mabry Pro Black';
  font-size: 42px;
  line-height: 42px;
}

.photo-gallery-title h4 {
  font-family: 'Mabry Pro Bold';
  font-size: 14px;
  line-height: 20px;
}

.photo-gallery-image {
  width: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 4 / 3;
}

@media (min-width: 620px) {
  .poi-detail-photo-gallery {
    margin-bottom: 64px;
  }

  .poi-detail-photo-gallery ul {
    flex-flow: row wrap;
    column-gap: 12px;
  }

  .photo-gallery-image {
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 4 / 3;
  }

  .photo-gallery-title h3 {
    font-family: 'Mabry Pro Black';
    font-size: 48px;
    line-height: 48px;
  }

  .photo-gallery-title h4 {
    font-family: 'Mabry Pro Bold';
    font-size: 16px;
    line-height: 22px;
  }
}
