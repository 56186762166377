.vertical-list li {
  display: flex;
  border-top: 1px solid white;
  line-height: 68px;
  flex-grow: 1;
  justify-content: left;
  justify-items: left;
  align-items: center;
  min-height: 70px;
}
