.badge-circle {
  display: block;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-grow: 1;
}
.badge-cicle img {
  border: 2px solid black;
  border-radius: 50%;
  /* padding: 6px; */
  overflow: visible;
  object-fit: scale-down;
}
