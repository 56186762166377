.vertical-list ul {
  height: 100%;
  width: 100%;
  list-style: none;
  padding: 20px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 4rem;
  display: flex;
  /* display: table; */
  flex-direction: column;
  justify-content: space-between;
  /* align-content: center; */
  align-items: left;
  align-content: left;
}
