.social-media-buttons {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  border-top: 1px solid black;
  padding-top: 24px;
  margin-top: auto;
}

.social-media-buttons h4 {
  font-family: 'Mabry Pro Bold';
  font-size: 12px;
  line-height: 16px;
}

.social-media-buttons ul {
  display: flex;
  list-style: none;
  justify-content: flex-start;
  align-content: center;
  column-gap: 16px;
}

.social-media-buttons img {
  height: 23px;
  width: 23px;
}
