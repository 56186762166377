.home-body {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 60px;
  padding-bottom: 15vh;
  background-color: #cfddbb;
  height: 100%;
  /*height: -moz-calc(100vh - 30px);*/ /* Firefox */
  /*height: -webkit-calc(100vh - 30px);*/ /* Chrome, Safari */
  /*height: calc(100vh - 30px);*/ /* IE9+ and future browsers */
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: stretch;
  overflow: hidden;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: black;
}

@media (min-width: 660px) {
  .home-body {
    height: 100%;
    /*height: -moz-calc(100vh - 75px);*/ /* Firefox */
    /*height: -webkit-calc(100vh - 75px);*/ /* Chrome, Safari */
    /*height: calc(100vh - 75px);*/ /* IE9+ and future browsers */
    justify-content: space-evenly;
    align-items: center;
  }

  .lang-selector button {
    line-height: 20px;
  }
}
