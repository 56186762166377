.map-legend-item {
  font-family: 'Mabry Pro Medium';
  font-size: 11px;
  line-height: 15px;
  /* margin-top: 8px; */
  width: 75px;
  height: 90px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 6px;
  text-align: center;
}

.map-legend-item span {
  font-family: 'Mabry Pro Medium';
  font-size: 11px;
  line-height: 15px;
  color: white;
}
