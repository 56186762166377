.detail-image {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 4/3;
}

@media (min-width: 620px) {
  .detail-image {
    aspect-ratio: 4/3;
    max-height: 450px;
    object-fit: cover;
    object-position: center;
  }
}
