.poi-detail-body {
  display: flex;
  flex-grow: 1;
  top: 0;
  right: 0;
  width: clamp(320px, 100%, 620px);
  min-height: 100%;
  position: absolute;
  flex-direction: column;
  align-self: flex-end;
  overflow: scroll;
  overflow-x: hidden;
  height: 100%;

  z-index: 11;

  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.poi-detail-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 22px;
}

.poi-detail-close-btn {
  width: 75px;
  height: 30px;
  font-family: 'Mabry Pro Bold';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.poi-detail-close-btn-link {
  position: fixed;
  align-self: flex-end;
  padding-top: 4px;
  padding-bottom: 8px;
  margin-top: 12px;
  margin-right: -3px;
  z-index: 12;
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

.display-none {
  display: none;
}

.poi-loader,
.poi-loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
  align-self: center;
  justify-self: center;
}
.poi-loader {
  margin: 60px auto;
  /* margin: 0; */
  font-size: 10px;

  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.2);
  border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
