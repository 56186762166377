.lang-selector {
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  column-gap: 15px;
}

.lang-selector button {
  line-height: 20px;
}
