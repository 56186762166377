.menu-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.menu-header img {
  width: 170px;
  margin-right: auto;
}

.menu-header button {
  width: 75px;
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: 'Mabry Pro Bold';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin-left: auto;
}
