.to-front {
  z-index: 2;
}

.info-body {
  flex-grow: 1;
  top: 0;
  right: 0;
  width: clamp(320px, 100%, 620px);
  min-height: 100%;
  height: 100%;
  position: absolute;
  flex-direction: column;
  align-self: flex-end;
  overflow: scroll;
  overflow-x: hidden;

  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;

  z-index: 11;
}

.info-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 22px;
}

/* .info-close-btn {
  position: fixed;
  align-self: flex-end;
  width: 75px;
  height: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: 'Mabry Pro Bold';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin-top: 12px;
  margin-right: -2px;
} */

.info-close-btn {
  width: 75px;
  height: 30px;
  font-family: 'Mabry Pro Bold';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.info-close-btn-link {
  position: fixed;
  align-self: flex-end;
  padding-top: 4px;
  padding-bottom: 8px;
  margin-top: 12px;
  margin-right: -3px;
  z-index: 12;
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

.display-none {
  display: none;
}

@media (min-width: 620px) {
  .info-body {
    right: 0;
    align-self: flex-end;
  }
}
