.button-pill {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Mabry Pro Bold';
  font-size: 14px;
  /* line-height: 20px; */
  letter-spacing: 0.5px;
  background-color: white;
  color: black;

  padding: 5px 16px 5px 16px; /*top right bottom left*/

  /* TODO: Confirm the following are needed... */
  /* text-align: center; */
  /* text-decoration: none; */
  /* vertical-align: middle; */
  /* display: flex;
  align-content: center;
  vertical-align: middle; */
  /* ...up to here */
  border: 2px solid #222222;
  border-radius: 500px;
  transition-duration: 0.4s;
}

.button-pill:hover {
  background-color: #222222;
  color: white;
}

.button-pill span {
  align-self: center;
  justify-self: center;
}

/* .button-pill.selected:hover,
.button-pill.selected.hover {
  background-color: #555555;
  color: white;
} */

.button-pill.selected,
.button-pill.selected:hover {
  background-color: #222222;
  color: white;
}

.light:hover {
  background-color: #cfddbb;
  color: black;
}

/* 
.light.selected:hover,
.light.selected.hover {
  background-color: #cfddbb;
  color: black;
} */

.light.selected,
.light.selected:hover {
  background-color: #cfddbb;
  color: black;
}
